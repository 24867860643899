<template>
	<div class="detail-wrap">
		<div class="help-detail" v-loading="loading">
			<div class="title">{{ detail.title }}</div>
			<div class="info">
				<div class="time">{{ $util.timeStampTurnTime(detail.create_time) }}</div>
			</div>
			<div class="content" v-html="detail.content"></div>
		</div>
	</div>
</template>

<script>
	import {
		ArticlefriendlyLink
	} from "@/api/website"
	import {
		articleDetail,
	} from '@/api/cms/article';
	export default {
		name: 'about_detail',
		components: {},
		data: () => {
			return {
				detail: [],
				linkList: [],
				loading: true,
				activeName: 'activeName'
			};
		},
		created() {
			this.id = this.$route.path.replace('/cms/about-', '');
			this.getDetail();
			this.link()
		},
		watch: {
			$route(curr) {
				this.id = curr.params.pathMatch;
				this.getDetail();
			}
		},
		methods: {
			link() {
				ArticlefriendlyLink({})
					.then(res => {
						if (res.code == 0 && res.data) {
							this.linkList = res.data.list
						}
					})
					.catch(err => {
						this.$message.error(err.message)
					})
			},
			getDetail() {
				articleDetail({
						id: this.id
					})
					.then(res => {
						if (res.code == 0) {
							if (res.data) {
								this.loading = false;
								this.detail = res.data;
							} else {
								this.$router.push({
									path: '/cms/about'
								});
							}
						}
					})
					.catch(err => {
						this.loading = false;
						this.$message.error(err.message);
					});
			},
			linkUrl(url, target) {
				if (!url) return
				if (url.indexOf("http") == -1) {
					if (target) this.$router.pushToTab({
						path: url
					})
					else this.$router.push({
						path: url
					})
				} else {
					if (target) window.open(url)
					else window.location.href = url
				}
			},
		}
	};
</script>
<style lang="scss" scoped>
	.help-detail {
		background-color: #ffffff;
		padding: 10px;
		border-radius: 5px;
		margin: 10px 0;

		.title {
			text-align: center;
			font-size: 18px;
			margin: 10px 0;
		}

		.info {
			margin: 0 43px;
			border-bottom: 1px dotted #e9e9e9;

			.time {
				text-align: center;
				color: #838383;
				margin-bottom: 17px;
			}
		}

		.content {
			padding-top: 10px;
			margin: 0 65px;
		}
	}

	.path {
		padding: 15px 0;
	}

	.friendly-link {
		width: $width;
		margin: 0 auto;
		border: 1px solid #e9e9e9;

		.link-title {
			line-height: 30px;
			padding: 10px 0 5px;
			margin: 0px 0 15px;
			border-bottom: 1px solid #e8e8e8;
		}

		.link-item {
			width: 10%;
			height: 50px;
			line-height: 47px;
			float: left;
			text-align: center;
			overflow: hidden;
			margin: 0 6px 10px 6px;
			-webkit-transition: opacity 0.3s, box-shadow 0.3s;
			transition: opacity 0.3s, box-shadow 0.3s;
			border: 1px solid #fff;
			border-left: none;
			cursor: pointer;
		}

		.link-item:hover {
			width: -webkit-calc(10% + 1px);
			width: calc(10% + 1px);
			margin-left: 4px;
			position: relative;
			opacity: 1;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			border: 1px solid #dddddd;
		}

		.clear {
			clear: both;
		}

		.el-tabs__active-bar is-top {
			width: 56px;
			transform: translateX(0px);
		}
	}
</style>
<style lang="scss">
	.friendly-link {
		.el-tabs__nav-scroll {
			padding-left: 30px;
			height: 50px;
			line-height: 50px;
		}

		.el-tabs__content {
			padding: 0 20px;
		}

		.el-tabs__nav-wrap::after {
			height: 1px;
		}

		.el-tabs__nav {
			padding: 0 20px;
		}

		.el-tabs__active-bar {
			padding: 0 20px;
		}

		.el-tabs__active-bar is-top {
			width: 56px;
			transform: translateX(0px);
		}
	}
</style>
